<template>
  <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="dialogVisible"
      width="30%"
      class="feedback-dialog"
      align="center"
      @close="cancel">
    <div>
      <el-form :model="dataForm" :rules="dataRule" label-position="left" label-width="auto">
        <el-form-item class="rate" label-width="60px" label="评分" prop="score" style="font-weight: bold;">
          <el-rate
              v-model="dataForm.score"
              :colors="colors"
              :texts="['极差', '差', '一般', '满意', '非常满意']"
              show-text>
          </el-rate>
        </el-form-item>
        <el-form-item prop="serviceComment">
          <el-input
              type="textarea"
              placeholder="请输入评价内容..."
              v-model="dataForm.serviceComment"
              :rows="12"
              maxlength="500"
              show-word-limit>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" @click="save">确定</el-button>
        </span>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    data() {
      return {
        dialogVisible: false,
        dataForm: {
          score: 0,
          serviceComment: '',
          id: ''
        },
        colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
        dataRule: {},

      }
    },
    created() {

    },
    methods: {
      cancel() {
        this.dialogVisible = false
        this.$emit('evaluateCallback')
      },
      init(item) {
        this.dataForm = item
        this.dialogVisible = true
      },
      save() {
        let dataForm=this.$deepClone(this.dataForm)
        dataForm.status = 2
        this.$http({
          url: this.$http.adornUrl(this.$api.CONSULT.UPDATE),
          method: 'post',
          data: this.$http.adornData(dataForm)
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message.success('操作成功')
            this.dialogVisible = false
            this.$emit('evaluateCallback')
          } else {
            this.$message.error(data.msg)
          }
        })
      }
    }
  }
</script>
<style scoped>
  .feedback-dialog .rate >>> .el-form-item__content {
    vertical-align: middle;
    display: flex;
    align-items: center;
    height: 36px;
  }

  .feedback-dialog .rate >>> .el-rate {
    height: auto;
  }

  .feedback-dialog >>> .el-rate__icon {
    font-size: 26px;
  }
</style>
