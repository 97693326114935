<template>
  <div>
    <div>
      <el-form :model="dataForm" :inline="true">
        <el-form-item prop="consultType">
          <el-select v-model="dataForm.status" placeholder="咨询状态" clearable>
            <el-option v-for="item in consultTypeList"
                       :key="item.dictId"
                       :label="item.dictName"
                       :value="item.dictId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="getEvaluteList">查询</el-button>
          <el-button :disabled="this.multipleSelection.length<=0" type="danger" @click="deleteClick()">批量删除</el-button>

        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table
          empty-text
          stripe
          v-loading="loading"
          :data="tableData"
          border
          style="width: 100%"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="50" align="center"></el-table-column>
        <el-table-column label="序号" type="index" :index="indexMethod" width="50" align="center"></el-table-column>
        <el-table-column label="手机号" prop="mobile" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column label="咨询内容" prop="consultContent" align="center" show-overflow-tooltip></el-table-column>

        <el-table-column label="咨询状态" prop="status" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status===0" type="danger">未受理</el-tag>
            <el-tag v-if="scope.row.status===1" type="success">已回访</el-tag>
            <el-tag v-if="scope.row.status===2" type="warning">已评价</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="评分" prop="score" min-width="150" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-rate
                disabled
                v-model="scope.row.score"
                :colors="colors"
                show-text
                :texts="['极差', '差', '一般', '满意', '非常满意']"
            >
            </el-rate>
          </template>
        </el-table-column>
        <el-table-column label="评价内容" prop="serviceComment" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button v-if="scope.row.status===1" type="text" icon="el-icon-edit" size="small"
                       @click="evaluateClick(scope.row)">评价
            </el-button>
            <el-button type="text" icon="el-icon-delete" style="color: #F56C6C" size="small"
                       @click="deleteconsult(scope.row.id)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          align="right"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[10,20,30,40]"
          :page-size="pageSize"
          layout="total, prev, pager, next,jumper,sizes"
          :total="total">
      </el-pagination>
      <!--      :evaluateVisable="evaluateVisable"-->
      <evaluateDialog ref="evaluating" v-if="evaluateVisable" @evaluateCallback="evaluateCallback"></evaluateDialog>
    </div>
  </div>

</template>
<script>
  import evaluateDialog from './evaluate-dialog'

  export default {
    components: {
      evaluateDialog
    },
    data() {
      return {
        loading: false,
        colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
        evaluateVisable: false,
        multipleSelection: [],
        dataForm: {
          status: '',
          mobile: ''
        },
        total: 0,
        currentPage: 1,
        pageSize: 10,
        tableData: [
          // {type:'1',mobilemobile:'13583187802'}
        ],
        consultTypeList: []
      }
    },
    computed: {
      userId: {
        get() {
          return this.$store.state.user.id
        }
      }
    },
    created() {
      this.$getDictList('BUSINESS_CONSULT_STATUS', (dict) => {
        this.consultTypeList = dict['BUSINESS_CONSULT_STATUS']
      })
      this.getEvaluteList()
    },
    methods: {
      handleSelectionChange(val) {
        this.multipleSelection = val
      },
      // 指标分页
      handleSizeChange(val) {
        this.pageSize = val
      },
      handleCurrentChange(val) {
        this.currentPage = val
      },
      evaluateClick(row) {
        this.evaluateVisable = true
        this.$nextTick(() => {
          this.$refs['evaluating'].init(this.$deepClone(row))
        })
      },
      deleteconsult(id) {
        let ids = [id]
        this.$confirm('确定要删除所选信息吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl(this.$api.CONSULT.DELETE),
            method: 'post',
            data: this.$http.adornData(ids, false)
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.$message.success('删除成功')
              this.getEvaluteList()
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      },
      deleteClick() {
        this.$confirm('确定要删除所选信息吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids = []
          this.multipleSelection.forEach(item => {
            ids.push(item.id)
          })
          this.$http({
            url: this.$http.adornUrl(this.$api.CONSULT.DELETE),
            method: 'post',
            data: this.$http.adornData(ids, false)
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.$message.success('删除成功')
              this.getEvaluteList()
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      },
      getEvaluteList() {
        this.loading = true
        this.$http({
          url: this.$http.adornUrl(this.$api.CONSULT.USER_ID_LIST),
          method: 'get',
          params: this.$http.adornParams({
            'userId': this.userId,
            'limit': this.pageSize.toString(),
            'page': this.currentPage.toString(),
            'mobile': this.dataForm.mobile,
            'status': this.dataForm.status
          })
        }).then(({data}) => {
          this.loading = false
          if (data && data.code === 0) {
            this.currentPage = data.page.currPage || 1
            this.pageSize = data.page.pageSize || 10
            this.total = data.page.totalCount || 0
            let tableData = data.page.list || []
            this.tableData = tableData
          }
        })
      },
      indexMethod(index) {
        return (this.currentPage - 1) * this.pageSize + (index + 1)
      },
      evaluateCallback() {
        this.evaluateVisable = false
        this.getEvaluteList()
      }
    }
  }
</script>

